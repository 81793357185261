import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./post-item.scss"

// import { BLOCKS, MARKS } from '@contentful/rich-text-types';
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const SitePosts = ({ data }) => {
  const {
    title,
    banner,
    bannerText,
    bannerBgColor,
    content,
    category,
    tags,
    createdAt,
    metaKeywords,
    metaDescription,
  } = data.contentfulPost
  console.log(content)

  // const options = {
  //   renderNode: {
  //     [BLOCKS.PARAGRAPH]: (node, children) => (
  //       <p className={paragraphClass(node)}>{children}</p>
  //     )
  //   }
  // }

  return (
    <Layout>
      <SEO title={title} />
      <main id="blogpost" className="main">
        <div className="container">
          <h1 className="title b-medium">{title}</h1>
          <div
            className="blogpost-hero blogpost-hero_gradient-black-070d23"
            style={{ backgroundColor: bannerBgColor }}
          >
            <div className="hero-description">
              <p>{bannerText}</p>
            </div>
            <figure className="hero-media">
              <img className="icon" alt={banner.title} src={banner.file.url} />
            </figure>
          </div>
          {/*{documentToReactComponents(description, options)}*/}
          <section
            className="main-content container2"
            dangerouslySetInnerHTML={{
              __html: content.childMarkdownRemark.html,
            }}
          ></section>
          {/*<p>{category}</p>*/}
          {/*<p>{tags.toString()}</p>*/}
          {/*<Link to="/blog">View more posts</Link>*/}
        </div>
      </main>
    </Layout>
  )
}

export default SitePosts

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      id
      slug
      title
      bannerText
      bannerBgColor
      banner {
        title
        file {
          url
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      category
      tags
      createdAt
      metaKeywords
      metaDescription
    }
  }
`
